import React from 'react';
import { dateFormat, textfieldDateFormat, shiftHours } from "../../../utils/dateFunctions";
import { getAPIData, handlePagination } from "../../../utils/apiFunction";
import MUIDataTable from "mui-datatables";
import { getOysterGrades, gradeSort } from "../../../definitions/oysters";
import { CircularProgress, Typography } from "@mui/material";
import DataTableStandard from "../../../components/DataTableStandard";
import { useJumboTheme } from "@jumbo/hooks";



const SalesOrders = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [procurement, setProcurement] = React.useState([]);
    const [grades, setGrades] = React.useState([]);
    const { theme } = useJumboTheme();
    const quantityLabel = 'Total Oyster Quantity';
    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(gradeSort(data));
        });
    }, []);

    React.useEffect(() => {
        var cols = [


            {
                name: 'name',
                label: 'Customer',
                options: props.customerFilter ? { filterList: [props.customerFilter] } : {}
            },

            {
                name: 'status',
                label: 'Status'
            },
            {
                name: 'startDate',
                label: 'Start Date'
            },
            {
                name: 'endDate',
                label: 'End Date'
            },
            {
                name: 'frequency',
                label: 'Frequency (weeks)'
            },
            {
                name: 'days',
                label: 'Days'
            },
            {
                name: 'description',
                label: 'Description'
            },
            //{
            //    name: 'quantity',
            //    label: quantityLabel
            //}, This is not showing the shipped quantity
            ...oysterCols,
            {
                name: 'nonStockQuantity',
                label: 'Non Stock Quantity'
            },


        ]

        setColumns(cols);
    }, [props.customerFilter, procurement, grades])
    var oysterCols = grades.map((og) => {
        return {
            name: String(og.id),
            label: og.abv,
            options: {
                filter: false
            }
        }
    })

    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        rowsPerPage: 1000,
        responsive: 'standard',
        onRowClick: props.onClick ? props.onClick(procurement) : (() => { }),
        setRowProps: row => {
            var selRow = row[1];
            if (selRow === ("Closed")) {
                return {
                    style: { background: theme.palette.oysterProducts.completed }
                };
            } else if (selRow === ("Ending Soon")) {
                return {
                    style: { background: 'orange' }
                };
            } else if (selRow === ("Active")) {
                return {
                    style: { background: theme.palette.oysterProducts.open }
                };
            } else if (selRow === ("Not Started")) {
                return {
                    style: { background: theme.palette.oysterProducts.onhold }
                };
            }
        }
    };
    const getStatus = (startDate, endDate) => {
        var today = new Date();
        if (today > endDate) {
            return 'Closed';
        } else if ((today > startDate) && (today < endDate)) {
            if ((shiftHours(today, 24 * 14) > endDate)) {
                return 'Ending Soon';
            }
            return 'Active';
        } else if (today < startDate) {
            return 'Not Started';
        }
    }


    const formatTableData = (data) => {
        var textDays = ['S', 'M', 'Tu', 'W', 'Th', 'F', 'S'];
        var newData = data.sort((a, b) => { return new Date(a.startDate) < new Date(b.startDate) ? 1 : -1 }).map((d) => {
            return {
                id: d.id,
                name: d.customer.name,
                status: getStatus(new Date(d.startDate), new Date(d.endDate)),
                startDate: dateFormat(d.startDate),
                unFormStartDate: (d.startDate),
                endDate: dateFormat(d.endDate),
                unFormEndDate: (d.endDate),
                frequency: d.frequency,
                days: d.salesSequenceDays.map((s) => {
                    return textDays[s.day];
                }).join(','),
                description: d.description,
                ...grades.reduce((p, c) => {
                    p[String(c.id)] = d.salesSequenceLines.filter((og) => og.stockItem?.gradeId === c.id).reduce((a, b) => {
                        return a + b.quantity;
                    }, 0)
                    return p;
                }, {}),
                nonStockQuantity: d.salesSequenceLines.filter((og) => og.stockItem?.isNonStockItem).reduce((a, b) => {
                    return a + b.quantity;
                }, 0),
            }

        });
        setProcurement(newData);
    }

    React.useEffect(() => {
        const abortController = new AbortController();
        setProcurement([]);
        setLoading(true);
        if (grades) {
            getAPIData('/SalesSequence/GetAll', formatTableData)
        }
        return () => {
            abortController.abort();
        };
    }, [props.location, props.startDate, props.endDate, props.reload, grades]);


    return (
        <DataTableStandard
            data={props.filter ? props.filter(procurement) : procurement}
            title={<Typography variant={'h4'} >Sales Series</Typography>}
            columns={columns}
            options={options}
            returnSearch={!loading}
            reload={props.reloadFunc}
            shrink={true}

        />
    );
};

export default SalesOrders;
