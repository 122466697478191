import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { startOfWeek, undoDateFormat, textfieldDateFormat, dateFormat, adjustDateByStockItem } from "../../../utils/dateFunctions";
import ControlledTextField from "../../../components/ControlledTextField";
import Div from "@jumbo/shared/Div";
import IconButton from '@mui/material/IconButton';
import { useJumboTheme } from "@jumbo/hooks";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import DatePicker from "../../../shared/DatePicker";
import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import FormModal from '../../../components/FormModal';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import SubmitButtonDisplayer from "../../../components/SubmitButtonDisplayer";
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import StaffMember from "../../../components/StaffMember";
import SelectWarehouse from "../../../components/SelectWarehouse";
import { checkAccess } from '../../../utils/roleFunctions';
import { getAPIDataParamsReturn, postApi, getAPIData } from "../../../utils/apiFunction";
import { userInfo } from '../../../../adalConfig';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { SelectTextFieldNew as SelectTextField } from "../../../components/SelectTextField";
import JumboSearch from '../../../../@jumbo/components/JumboSearch';
import { v4 as uuidv4 } from 'uuid';
const PriceForm = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [staffMember, setStaffMember] = React.useState(null);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [currStockItems, setcurrStockItems] = React.useState([]);
    const [date, setDate] = React.useState(props.date ? props.date : new Date());
    const [comments, setComments] = React.useState(null);
    const [currentStock, setCurrentStock] = React.useState(null);
    const [oysters, setOysters] = React.useState([]);
    const [returnData, setReturnData] = React.useState(null);
    const [stockItems, setStockItems] = React.useState([]);
    const [warehouse, setWarehouse] = React.useState(null);
    const [currentEntries, setCurrentEntries] = React.useState(null);
    const [outputsPassedGroup, setOutputsPassedGroup] = React.useState([]);
    const [reload, setReload] = React.useState(false);
    const [fullList, setFullList] = React.useState([]);
    const [newLines, setNewLines] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [inventory, setInventory] = React.useState(null);
    const [currentStocktake, setCurrentStocktake] = React.useState(null);
    const [editST, setEditST] = React.useState(false);
    const [newList, setNewList] = React.useState([]);
    const [sortKey, setSortKey] = React.useState({ main: 'expiryDate', second: null });
    const id = props.data ? props.data.id : '';
    const [user, setUser] = React.useState(userInfo());
    const frozenStocktake = false;
    const Swal = useSwalWrapper();
    const requiredEditAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.EDIT };
    const requiredApprovalAccess = { key: ACCESS_SECTIONS.PROCESSING, value: ACCESS.ADMIN };
    const currentStocktakeId = props.selStocktakeId ? props.selStocktakeId : props.currentStocktakeId;
    const isComplete = currentStocktake ? currentStocktake.isComplete : false;
    const isApproved = currentStocktake ? (currentStocktake.approvedBy !== null ? true : false) : false;
    const dummyFunction = async () => { return [] };

    const getInvData = React.useCallback(async () => {
        return await getAPIDataParamsReturn('/Inventory/Get', { WarehouseId: props.warehouseId }).then((data) => {
            return data = data.filter((d) => {
                if (frozenStocktake) {
                    return d.stockItem?.isFrozen;
                } else {
                    return !d.stockItem?.isFrozen;
                }
            });
        });
    }, [props.warehouseId]);

    React.useEffect(() => {
        setLoading(true);
        setInventory(null);

        if (currentStocktakeId) {
            getAPIDataParamsReturn('/Inventory/GetStocktake', { StocktakeId: currentStocktakeId }).then((cs) => {
                const localIsApproved = cs.approvedBy !== null ? true : false;
                (localIsApproved ? dummyFunction() : getInvData()).then((data) => {

                    setLoading(false);
                    var nFL = [];
                    var nInv = [];
                    var nonNewEntries = [];
                    for (var i = 0; i < data.length; i++) {
                        var ExistCS = cs.stocktakeLines.find((c) => ((c.stockItemId === data[i].stockItemId) && (c.expiryDate === data[i].expiryDate) && (c.lotSerialNbr === data[i].lotSerialNbr)));
                        if (ExistCS) {
                            nonNewEntries.push(ExistCS);
                        }
                        nFL.push({ id: i, quantity: ExistCS ? ExistCS.newQuantity : null });
                        nInv.push({ ...data[i], id: i, touched: ExistCS ? true : false, stocktakeQuantity: ExistCS ? ExistCS.newQuantity : null });
                    }
                    for (var i = 0; i < cs.stocktakeLines.length; i++) {
                        if (!nonNewEntries.find((nne) => (nne.id === cs.stocktakeLines[i].id))) {
                            var newId = uuidv4();
                            nFL.push({ id: newId, quantity: cs.stocktakeLines[i].newQuantity });
                            nInv.push({ ...cs.stocktakeLines[i], id: newId, touched: true, stocktakeQuantity: cs.stocktakeLines[i].newQuantity, stocktakeOldQuantity: cs.stocktakeLines[i].oldQuantity });
                        }
                    }
                    setCurrentStocktake(cs);
                    setFullList(nFL);
                    setInventory(nInv);
                    if (cs.isComplete) {
                        setSortKey(null)
                    }
                });
            });
        } else {
            getInvData().then((data) => {
                setLoading(false);
                setFullList(data.map((dt, ind) => ({ id: ind, quantity: null })));
                setInventory(data.map((dt, ind) => ({ ...dt, id: ind })));
            });
        }
    }, [reload, props.reload, props.warehouseId]);

    React.useEffect(() => {
        const remNoStockItems = (stockItems) => {
            var newStockItems = stockItems.filter((si) => {
                return !si.isNonStockItem;
            });
            setStockItems(newStockItems);
        }
        if (newLines) {
            getAPIData('/StockItems/Get', remNoStockItems)
        }
    }, [newLines]);
    console.log(stockItems);
    const validate = (complete) => () => {
        var warningMessages = [];
        var errorMessages = [];
        if (!staffMember) {
            errorMessages.push("You must enter a Staff Member");
        }
        if (complete) {
            for (var i = 0; i < fullList.length; i++) {
                if (fullList[i].quantity === null) {
                    errorMessages.push("You must report a status for each item in this list");
                    break;
                } else {
                    if (fullList[i].quantity < 0) {
                        errorMessages.push("You must enter a quantity greater than or equal to 0 for all items.");
                        break;
                    }
                }
            }
        }
        console.log(newList);
        for (var i = 0; i < newList.length; i++) {
            if (!newList[i].stockItem) {
                errorMessages.push("You must select a stock item for all new lines");
                break;
            }
            if (!newList[i].lotSerialNbr) {
                errorMessages.push("You must select a lot serial number for all new lines");
                break;
            }
            if (!newList[i].expiryDate) {
                errorMessages.push("You must select an expiry date for all new lines");
                break;
            }
            var quant = fullList.find((fl) => fl.id === newList[i].id)?.quantity;
            if (!quant || quant === '') {
                errorMessages.push("You must enter a quantity for all new lines");
                break;
            }
        }
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else {
            submit(complete);
        }
    }
    console.log(newList);
    const submit = async (complete) => {
        var stocktakeId = currentStocktake ? currentStocktake.id : null;
        if (!currentStocktake) {
            //create stocktake
            var currStocktake = await postApi('/Inventory/CurrentStocktake', { WarehouseId: props.warehouseId, StaffMemberId: staffMember?.id });
            var cst = await currStocktake.json()
            stocktakeId = cst.id;
        }
        setLoading(true);
        //match fullist to inventory then submit
        var promArr = [];
        for (var i = 0; i < fullList.length; i++) {
            if ((fullList[i].quantity !== null) && (!fullList[i].new)) {
                var invSel = inventory.find((inv) => inv.id === fullList[i].id);
                if (invSel) {
                    var params = {
                        StocktakeId: stocktakeId,
                        LotSerialNbr: invSel.lotSerialNbr,
                        StockItemId: invSel.stockItemId,
                        ExpiryDate: textfieldDateFormat(new Date(invSel.expiryDate)),
                        Quantity: fullList[i].quantity === '' ? 0 : fullList[i].quantity
                    }
                    promArr.push(postApi('/Inventory/AddUpdateStockTakeItem', params));
                } else {
                    console.log('error lists dont match');
                }

            }
        }
        for (var i = 0; i < newList.length; i++) {
            var params = {
                StocktakeId: stocktakeId,
                LotSerialNbr: newList[i].lotSerialNbr,
                StockItemId: newList[i].stockItem.id,
                ExpiryDate: textfieldDateFormat(new Date(newList[i].expiryDate)),
                Quantity: fullList.find((fl) => fl.id === newList[i].id)?.quantity
            }
            promArr.push(postApi('/Inventory/AddUpdateStockTakeItem', params));
        }
        await Promise.all(promArr);
        if (complete) {
            var paramsc = {
                StocktakeId: stocktakeId
            }
            await postApi('/Inventory/CompleteStocktake', paramsc);
        }


        setLoading(false);
        setCompleted(true);
        if (props.reload) {
            props.reload();
        }
    }
    const approve = async () => {
        setLoading(true);
        if (editST) {
            validate(false)();
        }
        await postApi('/Inventory/ApproveStocktake', { StocktakeId: currentStocktake.id, Approver: user.name });
        setLoading(false);
        setCompleted(true);
        if (props.reload) {
            props.reload();
        }
    }
    const sortBy = (key, second) => () => {
        if ((sortKey?.main === key) && (sortKey?.second === second)) {
            setSortKey({ main: key, second: second, pos: !sortKey.pos });
        } else {
            setSortKey({ main: key, second: second, pos: true });
        }
    }
    const setFullListById = React.useCallback((id, quantity) => {
        setFullList(prevFullList => {
            var findId = prevFullList.findIndex((fl) => fl.id === id);
            prevFullList[findId].quantity = quantity;
            return prevFullList;
        });
    }, []);
    const searchFilter = (data) => {
        if ((search === '') || (!search)) return true;
        if (data.stockItem?.description.toLowerCase().includes(search?.toLowerCase()) ||
            data.lotSerialNbr?.toLowerCase().includes(search?.toLowerCase()) ||
            data.estuary?.description.toLowerCase().includes(search?.toLowerCase())) {
            return true;
        }
        return false;
    }
    const addNewLine = React.useCallback(() => {
        var newId = uuidv4();
        setFullList(prevFullList => {
            return [{ id: newId, new: true, quantity: null }, ...prevFullList];
        });
        setInventory(invList => {
            return [{ id: newId, new: true }, ...invList];
        });
        setNewList(newL => {
            return [{ id: newId, new: true }, ...newL];
        })
        setNewLines(true);
        setSearch('');
    }, []);


    const updateNewList = React.useCallback((id, expiryDate, lotSerialNbr, stockItem) => {
        setNewList(prevNewL => {
            var findId = prevNewL.findIndex((fl) => fl.id === id);
            if (expiryDate) {
                prevNewL[findId].expiryDate = expiryDate;
            }
            if (lotSerialNbr) {
                prevNewL[findId].lotSerialNbr = lotSerialNbr;
            }
            if (stockItem) {
                prevNewL[findId].stockItem = stockItem;
            }
            return prevNewL;
        });
    }, []);

    const removeNewLine = React.useCallback((id) => {
        setFullList(prevFullList => {
            return prevFullList.filter((fl) => fl.id !== id);
        });
        setInventory(invList => {
            return invList.filter((fl) => fl.id !== id);
        });
        setNewList(newL => {
            return newL.filter((fl) => fl.id !== id);
        });
    }, []);

    const inventorySort = React.useMemo(() => {
        return inventory?.sort((a, b) => {
            if (sortKey === null) {
                return (a.portalQuantity !== a.stocktakeQuantity) === (b.portalQuantity !== b.stocktakeQuantity) ? 0 : (a.portalQuantity !== a.stocktakeQuantity) ? -1 : 1;
            } else {
                var aOp = sortKey.second ? a[sortKey.main]?.[sortKey.second] : a[sortKey.main];
                var bOp = sortKey.second ? b[sortKey.main]?.[sortKey.second] : b[sortKey.main];

                if (aOp > bOp) {
                    return (sortKey.pos ? 1 : -1);
                } else if (aOp < bOp) {
                    return (sortKey.pos ? -1 : 1);
                }
                return 0;
            }
        })
    }, [inventory, sortKey]);
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >

            <Div>
                <Grid container spacing={2} mb={1}>
                    <Grid item xs={12} sm={12} md={6}>
                        <StaffMember staffId={currentStocktake ? currentStocktake.staffMemberId : null} driver={staffMember} setDriver={setStaffMember} disabled={currentStocktake} noTitle />
                        <SelectWarehouse useCache warehouseId={props.warehouseId ? props.warehouseId : null} state={warehouse} setState={setWarehouse} disabled={true} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        {isComplete ?
                            <div style={{ paddingTop: '8px', textAlign: 'left' }}>
                                <SubmitButtonDisplayer error={error} completed={completed || isApproved} loading={loading} allowed={checkAccess(props.access, requiredApprovalAccess)} submitFunc={approve} handleClose={props.handleClose} title='Approve' />
                            </div>
                            : <React.Fragment><div style={{ paddingTop: '8px', textAlign: 'left' }}>
                                <SubmitButtonDisplayer error={error} completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate(false)} handleClose={props.handleClose} title='Save' />
                            </div>
                                <div style={{ paddingTop: '8px', textAlign: 'left' }}>
                                    <SubmitButtonDisplayer error={error} completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate(true)} handleClose={props.handleClose} />
                                </div>
                            </React.Fragment>}
                    </Grid>
                </Grid>

                <h3 style={{ fontWeight: 450, display: 'inline' }}>Stock</h3>
                {!isApproved && isComplete && checkAccess(props.access, requiredApprovalAccess) ? <IconButton aria-label="Label" onClick={() => { setEditST(true) }} >
                    <EditIcon />
                </IconButton> : null}
                {!isComplete || (isComplete && editST) ? <IconButton aria-label="Label" onClick={() => { addNewLine() }} >
                    <AddIcon />
                </IconButton> : null}
                <JumboSearch onChange={(val) => { setSearch(val) }} />
                <Grid container spacing={2} mb={1}>

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell onClick={sortBy('expiryDate')} >Use by Date<SwapVertIcon style={{ marginBottom: '-5px' }} /></TableCell>
                                    <TableCell onClick={sortBy('lotSerialNbr')}>Lot Serial Nbr<SwapVertIcon style={{ marginBottom: '-5px' }} /></TableCell>
                                    <TableCell onClick={sortBy('stockItem', 'description')}>StockItem<SwapVertIcon style={{ marginBottom: '-5px' }} /></TableCell>
                                    <TableCell onClick={sortBy('estuary', 'description')}>Estuary<SwapVertIcon style={{ marginBottom: '-5px' }} /></TableCell>
                                    {isComplete ? <TableCell onClick={sortBy('portalQuantity')}>Old Quantity (Doz)<SwapVertIcon style={{ marginBottom: '-5px' }} /></TableCell> : null}
                                    <TableCell onClick={sortBy('portalQuantity')}>{isComplete ? "New Quantity (Doz)" : "Quantity (Doz)"}<SwapVertIcon style={{ marginBottom: '-5px' }} /></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {inventorySort ? (inventorySort).map((row, index) => {
                                    return <ModiTableRow show={searchFilter(row)} key={row.id} row={row} index={index} setFullListById={setFullListById} isComplete={isComplete} isApproved={isApproved} disabled={isApproved || (isComplete && !editST)} frozenStocktake={frozenStocktake} stockItems={stockItems} updateNewList={updateNewList} removeNewLine={removeNewLine} />

                                }) : null}
                            </TableBody>
                        </Table>
                    </Grid>

                </Grid>
            </Div>
        </Box >
    );
};

const ModiTableRow = (props) => {
    const newLine = props.row.new ? true : false;
    const [quantity, setQuantity] = React.useState(props.row.touched ? props.row.stocktakeQuantity : props.row.portalQuantity);
    const [keep, setKeep] = React.useState(null);
    const [expiryDate, setExpiryDate] = React.useState(null);
    const [lotSerialNbr, setLotSerialNbr] = React.useState(null);
    const [stockItem, setStockItem] = React.useState(null);
    const { theme } = useJumboTheme();
    var statColor = 'white';
    var compareQuantity = props.row.portalQuantity ? props.row.portalQuantity : 0;
    if (props.isApproved) {
        compareQuantity = (props.row.stocktakeOldQuantity ? props.row.stocktakeOldQuantity : 0)
    }
    if ((Number(compareQuantity) !== Number(quantity)) && (quantity !== 0)) {
        statColor = theme.palette.oysterProducts.open;
    } else if ((keep === true) || (props.row.touched && (Number(compareQuantity) === Number(quantity)))) {
        statColor = theme.palette.oysterProducts.completed;
    } else if ((keep === false) || (props.row.touched && (0 === Number(quantity)))) {
        statColor = theme.palette.oysterProducts.cancelled;
    }
    const updateLotSerialNbr = (lsn) => {
        console.log(lsn);
        setLotSerialNbr(lsn);
        var newExpDate = adjustDateByStockItem(undoDateFormat(lsn.substring(lsn.length - 8, lsn.length)), stockItem)
        setExpiryDate(newExpDate);
        props.updateNewList(props.row.id, newExpDate, lsn, stockItem);
    }
    const updateStockItem = (si) => {
        setStockItem(si);
        if (lotSerialNbr) {
            var newExpDate = adjustDateByStockItem(undoDateFormat(lotSerialNbr.substring(lotSerialNbr.length - 8, lotSerialNbr.length)), si)
            setExpiryDate(newExpDate);
        }

        props.updateNewList(props.row.id, newExpDate, lotSerialNbr, si);
    }
    const updateExpiryDate = (ed) => {
        setExpiryDate(ed);
        props.updateNewList(props.row.id, ed, lotSerialNbr, stockItem);
    }
    if (!props.show) {
        return null;
    }
    console.log(props.row, props)
    return <TableRow key={props.row.id} style={{ backgroundColor: statColor }} >
        <TableCell>{!newLine ? dateFormat(props.row.expiryDate) :
            <DatePicker date={expiryDate} setDate={updateExpiryDate} label="Date" />
        }
        </TableCell>
        <TableCell>{!newLine ? props.row.lotSerialNbr :
            <SelectLotSerialNbr state={lotSerialNbr} setState={updateLotSerialNbr} />}
        </TableCell>
        <TableCell>{!newLine ? props.row.stockItem?.description :
            <SelectTextField key={'si' + props.row.id} title="Stock Item" state={stockItem} nameFunc={(r) => r.description} list={props.stockItems?.filter((si) => {
                if (props.frozenStocktake) {
                    return ((si.isFrozen));
                } else {
                    return (!(si.isFrozen));
                }
            })} setState={updateStockItem} disabled={props.disabled} />}
        </TableCell>
        <TableCell>{!newLine ? props.row.estuary?.description : (lotSerialNbr ? lotSerialNbr.estuary?.description : null)}
        </TableCell>

        {props.isComplete ? <TableCell><ControlledTextField key={'bi' + props.row.id} title="" state={Number(compareQuantity)} setState={(quant) => { }} style={{ "-webkit-text-fill-color": '#475259' }} disabled={true} number width={'100px'} />
        </TableCell> : null}
        <TableCell><ControlledTextField key={'bi' + props.row.id} title="" state={quantity} setState={(quant) => { props.setFullListById(props.row.id, quant); setQuantity(quant) }} style={props.disabled ? { "-webkit-text-fill-color": '#475259' } : {}} disabled={props.disabled} number width={'100px'} />
        </TableCell>
        <TableCell>
            {!props.disabled ? <IconButton aria-label="Label" onClick={() => { setKeep(true); setQuantity(props.row.portalQuantity ? props.row.portalQuantity : 0); props.setFullListById(props.row.id, (props.row.portalQuantity ? props.row.portalQuantity : 0)) }} >
                <DoneIcon />
            </IconButton> : null}
            {!props.disabled ? <IconButton aria-label="Label" onClick={() => { setKeep(false); setQuantity(0); props.setFullListById(props.row.id, 0) }} >
                <CloseIcon />
            </IconButton> : null}
            {newLine ? <IconButton aria-label="Label" onClick={() => { props.removeNewLine(props.row.id) }} >
                <DeleteIcon />
            </IconButton> : null}
        </TableCell>

    </TableRow>
}

export default PriceForm;

const SelectLotSerialNbr = (props) => {
    const [prn, setPrn] = React.useState('');
    const [returnedLSNs, setReturnedLSNs] = React.useState([]);
    const [loadingLSN, setLoadingLSN] = React.useState(false);
    const [lotNbr, setLotNbr] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [loadingDetails, setLoadingDetails] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    }
    const searchPRN = () => {
        setLoadingLSN(true);
        getAPIDataParamsReturn('/Inventory/SearchPRN', { LotNbrSubString: prn }).then((rlsn) => {
            setReturnedLSNs(rlsn);
            if (rlsn.length === 1) {
                setLotNbr(rlsn[0]);
            }
            setLoadingLSN(false);
        });
        setOpen(true);
    }
    const selectLSN = (LSN) => () => {
        setLotNbr(LSN);
        setOpen(false);
        props.setState(LSN);
    }
    return (lotNbr ?
        <div>{lotNbr}
            <IconButton aria-label="Label" onClick={() => { setLotNbr(null) }} >
                <CloseIcon />
            </IconButton>
        </div>
        : <React.Fragment><FormModal open={open} handleClose={handleClose} title={""} fixedWidth>
            <Typography variant="h6" id="modal-modal-title"> Choose a Lot Serial Number</Typography>
            {loadingLSN ? <CircularProgress /> : (returnedLSNs.length > 0 ? returnedLSNs.map((rlsn) => {
                return <Button style={{ marginTop: '20px' }} onClick={selectLSN(rlsn)} variant="outlined" disabled={false} >{rlsn}</Button>
            }) : 'No Matching Lot Serial Numbers')}
        </FormModal >
            <TextField
                id="outlined-required"
                label="PRN"
                value={prn}
                onChange={(e) => setPrn(e.target.value)}
            />
            <Button style={{ marginTop: '20px' }} onClick={searchPRN} variant="outlined" disabled={false} ><SearchIcon /></Button>
        </React.Fragment>)
}
