import React from 'react';
import { undoDateFormat, textfieldDateFormat } from "../../../utils/dateFunctions";
import { numberFormat } from "../../../utils/numberFunctions";
import { handlePagination, postApi } from "../../../utils/apiFunction";
import MUIDataTable from "mui-datatables";
import { getOysterGrades, gradeSort } from "../../../definitions/oysters";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import WebhookIcon from '@mui/icons-material/Webhook';
import EditIcon from '@mui/icons-material/Edit';
import { useJumboTheme } from "@jumbo/hooks";
import { CircularProgress, Typography } from "@mui/material";
import { TableCell, TableRow, TableFooter } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import { tableTheme } from '../../../themes/tableTheme';
import { ACCESS, ACCESS_SECTIONS } from '../../../utils/constants/access';
import { adalApiFetch } from '../../../../adalConfig';
import DataTableStandard from "../../../components/DataTableStandard";
import { reverseSort } from '../../../utils/sortFunctions';
import { salesOrderAndShipmentsTypes, statusList } from '../../../utils/constants/selectFields';
import getShipSaleData from '../Function/GetShipSaleList';
import { DatePickerAutoOpen } from '../../../shared/DatePicker';
import TextField from '@mui/material/TextField';
import { SOstatusList } from '../../../utils/constants/selectFields';
import { SimpleStringSelect } from "../../../components/StringSelect";
import AddSalesOrder from '../Forms/AddSalesOrder';
import FormModal from '../../../components/FormModal';
import { checkAccess } from '../../../utils/roleFunctions';



const SalesOrders = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [loading, setLoading] = React.useState(false);
    const [columns, setColumns] = React.useState([]);
    const [procurement, setProcurement] = React.useState([]);
    const [grades, setGrades] = React.useState([]);
    const [colourOverride, setColourOverride] = React.useState([]);
    const { theme } = useJumboTheme();
    const requiredEditAccess = { key: ACCESS_SECTIONS.SALES, value: ACCESS.ADMIN };
    const quantityLabel = 'Total Oyster Quantity';

    React.useEffect(() => {
        getOysterGrades().then((data) => {
            setGrades(gradeSort(data));
        });
    }, []);
    React.useEffect(() => {
        var cols = [
            {
                name: 'myobid',
                label: 'Order Number',
                options: {
                    customBodyRenderLite: (dataIndex) => {
                        var tenant = process.env.REACT_APP_MYOB_TENANT ? process.env.REACT_APP_MYOB_TENANT : 'AOCLive';
                        var proc = procurement;
                        return <Link href={'https://aoc.myobadvanced.com/Main?CompanyID=' + tenant + '&ScreenId=SO301000&OrderType=SO&OrderNbr=' + proc[dataIndex]?.myobid} target='_blank'>{proc[dataIndex]?.myobid}</Link>
                    },
                    sortCompare: reverseSort
                }
            },
            {
                name: 'orderDate',
                label: 'Order Date',
            },
            {
                name: 'name',
                label: 'Customer',
                options: props.customerFilter ? { filterList: [props.customerFilter] } : {}
            },

            {
                name: 'status',
                label: 'Status'
            },
            {
                name: 'shipmentDate',
                label: 'Shipment Date'
            },
            {
                name: 'orderTypeName',
                label: 'Order Type'
            },
            {
                name: 'description',
                label: 'Description'
            },
            //{
            //    name: 'quantity',
            //    label: quantityLabel
            //}, This is not showing the shipped quantity
            ...oysterCols,
            {
                name: 'nonStockQuantity',
                label: 'Non Stock Quantity'
            },


        ]
        if (props.editOne) {

            cols.push({
                name: 'editOrder',
                label: 'Edit Order',
                options: {
                    customBodyRenderLite: (dataIndex) => {
                        return <IconButton aria-label="cci" onClick={props.editOne() ? props.editOne(procurement, dataIndex) : () => { }}>
                            <EditIcon sx={{ fontSize: 10 }} />
                        </IconButton>
                    },
                }
            })
        }
        if (props.editAll) {
            cols.push({
                name: 'seriesLink',
                label: 'Edit Series',
                options: {
                    customBodyRenderLite: (dataIndex) => {
                        if (procurement[dataIndex]?.seriesId) {
                            return <IconButton aria-label="cci" onClick={props.editAll() ? props.editAll(procurement, dataIndex) : () => { }}>
                                <WebhookIcon sx={{ fontSize: 10 }} />
                            </IconButton>
                        } else {
                            return null;
                        }
                    },
                }
            });
        }
        setColumns(cols);
    }, [props.customerFilter, procurement])
    var oysterCols = grades.map((og) => {
        return {
            name: String(og.id),
            label: og.abv,
            type: 'OyCol',
            options: {
                filter: false
            }
        }
    });
    const changeStatus = (index) => (newStatus) => {
        var statusOR = colourOverride.findIndex((col) => { return col.index === index });
        if (statusOR >= 0) {
            var newCol = [...colourOverride];
            newCol[statusOR].status = newStatus;
            setColourOverride(newCol);
        } else {
            setColourOverride([...colourOverride, { index: index, status: newStatus }]);
        }
    }
    const options = {
        filterType: 'dropdown',
        selectableRowsHideCheckboxes: true,
        fixedHeader: true,
        rowsPerPage: 1000,
        responsive: 'standard',
        onRowClick: props.onClick ? props.onClick(procurement) : (() => { }),
        setRowProps: row => {
            var selRow = row[3];
            if (selRow === ("Completed")) {
                return {
                    style: { background: theme.palette.oysterProducts.completed }
                };
            } else if (selRow === ("Shipping")) {
                return {
                    style: { background: theme.palette.oysterProducts.shipping }
                };
            } else if (selRow === ("Invoiced")) {
                return {
                    style: { background: theme.palette.oysterProducts.completed }
                };
            } else if (selRow === ("Confirmed")) {
                return {
                    style: { background: 'rgba(152, 251, 152,0.3)' }
                };
            } else if (selRow === ("Open")) {
                return {
                    style: { background: theme.palette.oysterProducts.open }
                };
            } else if (selRow === ("Awaiting Release")) {
                return {
                    style: { background: 'rgba(152, 251, 152,0.3)' }
                };
            } else if (selRow === ("Canceled")) {
                return {
                    style: { background: theme.palette.oysterProducts.cancelled }
                };
            } else if (selRow === ("Closed")) {
                return {
                    style: { background: theme.palette.oysterProducts.cancelled }
                };
            }
        },
        customRowRender: (data, dataIndex, rowIndex) => {
            var statusOR = colourOverride.find((col) => { return col.index === dataIndex });
            var rowStyle = {
                background: getColour(statusOR ? statusOR.status : data[3]),
            };
            var cellStyle = {
                fontSize: '12px',
                paddingTop: '3px',
                paddingBottom: '3px',
                whiteSpace: 'nowrap',
                maxWidth: '150px',
                overflow: 'hidden'

            };
            var status = data[columns.findIndex((col) => { return col.name === 'status' })];
            return <TableRow key={dataIndex} style={rowStyle} >{columns.map((coll, i) => {
                if (coll.name === 'myobid') {
                    return <TableCell style={cellStyle}>{data[i](dataIndex)}</TableCell>
                }
                if (((status === "Open") || (status === "On Hold") || (status === "Proposed")) && checkAccess(props.access, requiredEditAccess)) {
                    if ((coll.name === 'orderDate') || (coll.name === 'shipmentDate')) {
                        return <DateTableCell style={cellStyle} date={data[i]} id={procurement[dataIndex]?.id} dateType={(coll.name === 'orderDate') ? 'OrderDate' : 'ShipmentDate'} />
                    } else if ((coll.name === 'description')) {
                        return <TextTableCell style={cellStyle} text={data[i]} id={procurement[dataIndex]?.id} />
                    } else if ((coll.name === 'status')) {
                        return <StatusTableCell style={cellStyle} data={data[i]} id={procurement[dataIndex]?.id} changeStatus={changeStatus(dataIndex)} />
                    } else if ((coll.type === 'OyCol')) {
                        return <EditOystersCell access={props.access} style={cellStyle} col={coll} data={data[i]} salesOrder={procurement[dataIndex]} id={procurement[dataIndex]?.id} reloadFunc={props.reloadFunc} />
                    } else {
                        return <TableCell style={cellStyle}>{data[i]}</TableCell>
                    }
                }
                return <TableCell style={cellStyle}>{data[i]}</TableCell>

            })}</TableRow>
        }
    };
    if (props.summary) {
        options.customTableBodyFooterRender = (opts) => {
            var proc = props.filter ? props.filter(procurement) : procurement;
            console.log(opts.data);
            var openTotals = calculateTotalSum(proc, opts.data.filter((dt) => ((dt.data[3] !== 'Completed') && (dt.data[3] !== 'Closed') && (dt.data[3] !== 'Invoiced') && (dt.data[3] !== 'Canceled') && (dt.data[3] !== 'Back Order'))));
            var CompletedTotals = calculateTotalSum(proc, opts.data.filter((dt) => ((dt.data[3] === 'Completed') || (dt.data[3] === 'Invoiced') || (dt.data[3] === 'Closed'))));
            var totals = calculateTotalSum(proc, opts.data);
            var sumTotal = totals.reduce((p, c, ind) => { if (opts.columns[ind].label.length === 5) { return (p + c) } else { return p } }, 0);
            var sumCompletedTotal = CompletedTotals.reduce((p, c, ind) => { if (opts.columns[ind].label.length === 5) { return (p + c) } else { return p } }, 0);
            var sumOpenTotal = openTotals.reduce((p, c, ind) => { if (opts.columns[ind].label.length === 5) { return (p + c) } else { return p } }, 0);
            const paddColStyle = {
                color: '#000',
                paddingTop: '1px',
                paddingBottom: '1px'
            }
            return (
                <TableFooter >
                    <TableRow style={{ fontWeight: 'bold' }}>
                        {opts.columns.map((col, index) => {
                            if (index === 0) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    Active
                                </TableCell>

                            } else if (index === 6) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    {numberFormat(sumOpenTotal)}
                                </TableCell>
                            } else {

                                if (col.display === "true") {
                                    if (col.label.length === 5) {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>
                                                {numberFormat(openTotals[index])}
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>

                                            </TableCell>
                                        );
                                    }
                                }
                            }
                        })}
                    </TableRow>
                    <TableRow style={{ fontWeight: 'bold' }}>
                        {opts.columns.map((col, index) => {
                            if (index === 0) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    Completed
                                </TableCell>
                            } else if (index === 6) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    {numberFormat(sumCompletedTotal)}
                                </TableCell>
                            } else {

                                if (col.display === "true") {
                                    if (col.label.length === 5) {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>
                                                {numberFormat(CompletedTotals[index])}
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>

                                            </TableCell>
                                        );
                                    }
                                }
                            }
                        })}
                    </TableRow>
                    <TableRow style={{ fontWeight: 'bold' }}>
                        {opts.columns.map((col, index) => {
                            if (index === 0) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    Total
                                </TableCell>
                            } else if (index === 6) {
                                return <TableCell key={index} style={{
                                    fontWeight: 'bold', ...paddColStyle
                                }}>
                                    {numberFormat(sumTotal)}
                                </TableCell>
                            } else {

                                if (col.display === "true") {
                                    if (col.label.length === 5) {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>
                                                {numberFormat(totals[index])}
                                            </TableCell>
                                        );
                                    } else {
                                        return (
                                            <TableCell key={index} style={{
                                                ...paddColStyle
                                            }}>

                                            </TableCell>
                                        );
                                    }
                                }
                            }
                        })}
                    </TableRow>
                </TableFooter>
            );
        }
    }

    const getColour = (selRow) => {
        if (selRow === ("Completed")) {
            return theme.palette.oysterProducts.completed
        } else if (selRow === ("Shipping")) {
            return theme.palette.oysterProducts.shipping
        } else if (selRow === ("Invoiced")) {
            return theme.palette.oysterProducts.completed
        } else if (selRow === ("Confirmed")) {
            return 'rgba(152, 251, 152,0.3)'
        } else if (selRow === ("Open")) {
            return theme.palette.oysterProducts.open
        } else if (selRow === ("Awaiting Release")) {
            return 'rgba(152, 251, 152,0.3)'
        } else if (selRow === ("Canceled")) {
            return theme.palette.oysterProducts.cancelled
        } else if (selRow === ("Closed")) {
            return theme.palette.oysterProducts.cancelled
        }
    }
    const calculateTotalSum = (data, displayData) => {
        var totArr = [];
        console.log(displayData);
        if (data && data.length > 0) {
            for (var i = 0; i < columns.length; i++) {
                totArr.push(displayData.map((a) => {
                    var res = data[a.dataIndex][columns[i].name];
                    //if (columns[i].name === 'srsco') {
                    //    console.log(res);
                    //}
                    if (Number(res)) {
                        return res;
                    } else {
                        return '';
                    }
                }).reduce((a, b) => {
                    if (Number(b) || (Number(b) === 0)) {
                        return (a += Number(b))
                    } else {
                        return '';
                    }
                }, 0));
            }
        }
        return totArr;
    };

    React.useEffect(() => {
        const abortController = new AbortController();
        setProcurement([]);
        setColourOverride([]);
        getShipSaleData(props.location, textfieldDateFormat(props.startDate), textfieldDateFormat(props.endDate), abortController, setLoading, setProcurement);
        return () => {
            abortController.abort();
        };
    }, [props.location, props.startDate, props.endDate, props.reload]);


    return (
        <DataTableStandard
            data={props.filter ? props.filter(procurement) : procurement}
            title={<Typography variant={'h4'} >Sales Orders <span style={{ fontSize: '70%' }} >(If the order is completed the shipped QTY is shown)</span></Typography>}
            columns={columns}
            options={options}
            returnSearch={!loading}
            reload={props.reloadFunc}
            shrink={true}

        />
    );
};

export default SalesOrders;


const DateTableCell = (props) => {
    const [selected, setSelected] = React.useState(false);
    const [date, setDate] = React.useState(undoDateFormat(props.date));
    //new memoize function on change of date
    const updateValue = (newDate) => {
        if (textfieldDateFormat(newDate) !== textfieldDateFormat(undoDateFormat(props.date))) {
            var params = {};
            params[props.dateType] = textfieldDateFormat(newDate);
            postApi('/SalesOrder/UpdateField/' + props.id, params);
        }
        setDate(newDate);
    };

    if (selected) {
        return <TableCell {...props} style={{ maxWidth: '500px' }} ><DatePickerAutoOpen date={date} setDate={updateValue} /></TableCell>
    } else {
        return <TableCell {...props} onClick={() => { setSelected(true) }} >{props.date}</TableCell>
    }
}

const TextTableCell = (props) => {
    const [selected, setSelected] = React.useState(false);
    const [text, setText] = React.useState(props.text);
    const updateValue = (newData) => {
        if (newData !== props.text) {
            var params = {};
            params['Description'] = newData;
            postApi('/SalesOrder/UpdateField/' + props.id, params);
        }
        setText(newData);
    };

    if (selected) {
        return <TableCell {...props} style={{ maxWidth: '500px' }} > <TextField
            autoFocus
            disabled={props.disabled}
            id="desc"
            value={text}
            onChange={(event) => { updateValue(event.target.value) }}
            multiline
            InputLabelProps={{
                shrink: true,
            }}
            rows={4} />
        </TableCell>
    } else {
        return <TableCell {...props} onClick={() => { setSelected(true) }} >{props.text}</TableCell>
    }
}
const StatusTableCell = (props) => {
    const [selected, setSelected] = React.useState(false);
    const [data, setData] = React.useState(props.data);
    const updateValue = (newData) => {
        if (newData !== props.data) {
            var params = {};
            params['Status'] = newData;
            postApi('/SalesOrder/UpdateField/' + props.id, params);
        }
        setData(newData);
        props.changeStatus(newData);
    };

    if (selected) {
        return <TableCell {...props} style={{ maxWidth: '150px' }} >
            <SimpleStringSelect width={150} state={data} setState={updateValue} options={SOstatusList} default={0} autoFocus />
        </TableCell>
    } else {
        return <TableCell {...props} onClick={() => { setSelected(true) }} >{props.data}</TableCell>
    }
}

const EditOystersCell = (props) => {
    const [selected, setSelected] = React.useState(false);
    const [data, setData] = React.useState(props.data);
    const [completed, setCompleted] = React.useState(false);
    console.log(props.data, props.id);
    const handleClose = () => {
        setSelected(false);
    }
    if (selected) {
        return <TableCell {...props} style={{ maxWidth: '150px' }} >
            <FormModal open={selected} handleClose={handleClose} title={''} >
                <AddSalesOrder access={props.access} id={props.id} data={props.salesOrder} onlyOysters reload={props.reloadFunc} handleClose={handleClose} completed={completed} edit />
            </FormModal>{props.data}
        </TableCell>
    } else {
        return <TableCell {...props} onClick={() => { setSelected(true); }} >{props.data}</TableCell>
    }
}