import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { dateFormat, textfieldDateFormat } from "../../utils/dateFunctions";
import Div from "@jumbo/shared/Div";
import { getAPIData, getAPIDataReturn, getAPIDataParams, getAPIDataParamsReturn, postApi } from '../../utils/apiFunction';
import Autocomplete from '@mui/material/Autocomplete';
import DateSelector from "../../shared/DateSelector";
import { getPRNfrmLSN } from '../../utils/fulfilment/lsnFunction';
import { adalApiFetch } from '../../../adalConfig';
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import useSwalWrapper from "@jumbo/vendors/sweetalert2/hooks";
import ArticleIcon from '@mui/icons-material/Article';
import { v4 as uuidv4 } from 'uuid';
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import { checkAccess } from '../../utils/roleFunctions';
import ControlledTextField from "../../components/ControlledTextField";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { CircularProgress } from '@mui/material';
import { numberFormat } from '../../utils/numberFunctions';
import SubmitButtonDisplayer from "../../components/SubmitButtonDisplayer";
import SelectWarehouse from '../../components/SelectWarehouse';
import { saveLabel } from '../../utils/fulfilment/labels';
import SelectInventoryForm from './Forms/SelectInventoryForm';
import { WAREHOUSEMATCHES } from '../../utils/constants/warehouse';
import SelectAOCFarmWarehouse from '../../components/SelectAOCFarmWarehouse';
const outputStruct = { prn: null, quantity: null, units: null };
const FulfilmentForm = (props) => {
    const uri = process.env.REACT_APP_BASE_URL;
    const [completed, setCompleted] = React.useState(props.completed);
    const [loading, setLoading] = React.useState(false);
    const Swal = useSwalWrapper();

    const [outputs, setOutputs] = React.useState([]);
    const [saleItem, setSaleItem] = React.useState(null);
    const [stockItems, setStockItems] = React.useState([]);
    const [date, setDate] = React.useState(new Date());
    const [existingPRNs, setExistingPRNs] = React.useState([]);
    const [warehouse, setWarehouse] = React.useState(null);
    const [toWarehouse, setToWarehouse] = React.useState(null);
    const requiredEditAccess = { key: ACCESS_SECTIONS.FULFILMENT, value: ACCESS.EDIT };
    const [recs, setRec] = React.useState(null);
    const [grades, setGrades] = React.useState([]);
    const [speciesList, setSpeciesList] = React.useState([]);
    //const [relevantInv, setRelInv] = React.useState(null);







    const selectInv = (inv) => (value, indexs) => {
        var dataIndex = indexs.dataIndex;
        var rowIndex = indexs.rowIndex;
        if (!outputs.find((o) => ((o.ivn.lotSerialNbr === inv[dataIndex].lotSerialNbr) && (o.ivn.stockItemId === inv[dataIndex].stockItemId) && (o.ivn.expiryDate === inv[dataIndex].expiryDate)))) {
            var cpyOuts = [...outputs];
            cpyOuts.push({ id: uuidv4(), ivn: inv[dataIndex], quantity: null, units: null });
            setOutputs(cpyOuts);
        }
    }
    const updateWarehouse = (value) => {
        setWarehouse(value);
        setOutputs([]);
    }

    const validate = () => {
        var warningMessages = [];
        var errorMessages = [];
        console.log('ping')
        var total = 0;
        var stockq = 0;
        var stockt = 0;
        for (var i = 0; i < outputs.length; i++) {

            if (!outputs[i].quantity) {
                errorMessages.push('Quantity is required for all outputs');
            }
            if (outputs[i].quantity > (outputs[i].ivn.portalQuantity)) {
                warningMessages.push("You are using more stock then available, are you sure?");
            }

        }
        if (outputs.length <= 0) {
            errorMessages.push('Please add at least one item to transfer');
        }
        if (!warehouse) {
            errorMessages.push('Please select a Warehouse');
        }
        if (!toWarehouse) {
            errorMessages.push('Please select a Farm to transfer to');
        }

        if (!toWarehouse) {
            errorMessages.push('Please select a Farm to transfer to');
        }
        const formatErrorMessage = (array) => {
            var errString = '';
            array.map((a) => {
                errString = errString + a + '<br/>';
            });
            return errString;
        }
        if (errorMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(errorMessages),
                icon: 'error',
                title: 'Oops...',

            });
        } else if (warningMessages.length > 0) {
            Swal.fire({
                html: formatErrorMessage(warningMessages),
                icon: 'warning',
                title: 'Oops...',
                showCancelButton: true,
                confirmButtonText: 'Yes, Continue',
                cancelButtonText: 'No, I\'ll edit it',

            }).then(result => {
                if (result.value) {
                    setLoading(true);
                    submit();
                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            });
        } else {
            setLoading(true);
            //setCompleted(true);
            submit();
        }
    }

    const submit = async () => {
        //add new and update existing
        var newOuts = [];
        console.log(existingPRNs, toWarehouse);
        //create transfer add lines do loading
        for (var i = 0; i < outputs.length; i++) {
            var params = {
                LotSerNbr: outputs[i].ivn?.lotSerialNbr,
                StockItemId: outputs[i].ivn?.stockItemId,
                OldWarehouseId: warehouse?.id,
                NewWarehouseId: toWarehouse?.warehouseId,
                Amount: outputs[i].quantity
            }
            await postApi('/Processing/BackToWater', params);
        }

        //saveLabel(newOuts, saleItem, codeInfo, codeInfo.isHalfshell);
        setLoading(false);
        setCompleted(true);

    }
    var handleClose = () => {
        window.location.reload()
    }
    var selectedList = React.useMemo(() => {
        var sl = [];
        if (outputs) {
            for (var i = 0; i < outputs.length; i++) {
                sl.push({ lotSerialNbr: outputs[i].ivn.lotSerialNbr, stockItemId: outputs[i].ivn.stockItemId, expiryDate: outputs[i].ivn.expiryDate });
            }
        }
        return sl;
    }, [outputs])


    var typeCol = 0;


    var productCol = 0;

    var containerCol = undefined;
    var defaultCode = [typeCol, productCol, containerCol, undefined, undefined];




    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '24ch' },
            }}
            noValidate
            autoComplete="off"
        >

            <Typography variant={"h1"} mb={0}>Back To Water</Typography>
            <br />
            <br />
            <Div>


                <Grid container spacing={2} mb={1}>

                    <Grid item xs={12} md={6} >
                        <SelectWarehouse access={props.access} enforceAccess={true} useCache warehouseId={null} state={warehouse} setState={updateWarehouse} disabled={false} all />
                        {warehouse ? <SelectInventoryForm onClick={selectInv} selectedList={selectedList} access={props.access} warehouseId={warehouse.id} typeDefault={defaultCode[0]} productDefault={defaultCode[1]} containerDefault={defaultCode[2]} speciesDefault={defaultCode[3]} gradeDefault={defaultCode[4]} hideHalfshell={true} hideFrozen={true} hideSpecies={true} hideLive={false} /> : null}
                    </Grid>
                    <Grid item xs={12} md={6} >
                        {/*<Div>*/}

                        {/*    <TextField*/}
                        {/*        disabled*/}
                        {/*        id="outlined-required"*/}
                        {/*        label="Date"*/}
                        {/*        value={dateFormat(date)}*/}
                        {/*        InputLabelProps={{*/}
                        {/*            shrink: true,*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</Div>*/}
                        <Grid container spacing={2} mb={1}>
                            <br />
                            <br />
                            <Typography variant={"h2"} mb={0}>Transfer To:</Typography>
                            <SelectAOCFarmWarehouse notFirstOne access={props.access} useCache warehouseId={null} state={toWarehouse} setState={setToWarehouse} disabled={false} all showAll />

                            {/*<Grid item xs={12} md={6} >*/}
                            {/*    <Typography variant={"h2"} mb={0}>{saleItem.customer?.name}</Typography>*/}
                            {/*    <Typography variant={"h5"} mb={0}>Order No: {saleItem.orderNo}</Typography>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={12} md={6} >*/}
                            {/*    <Typography variant={"h4"} mb={0}>{codeInfo?.description}</Typography>*/}
                            {/*    <Typography variant={"h4"} mb={0}>{codeInfo?.grade?.code + (codeInfo?.traySize ? (' (' + (codeInfo?.traySize) + ')') : '')}</Typography>*/}
                            {/*    <Typography variant={"h5"} mb={0}>{saleItem.code}</Typography>*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={12} >*/}
                            {/*    <Typography variant={"h3"} mb={0}>Total: {saleItem.quantity}</Typography>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12} >
                                {outputs ? <PRNList outputs={outputs} setOutputs={setOutputs} disabled={completed} id={props.id} quantPerUnit={0} recs={recs} codeInfo={null} saleItem={saleItem} isHalfshell={false} existingPRNs={existingPRNs} warehouse={toWarehouse} /> : <CircularProgress />}
                            </Grid>

                            {/*<StaffMember staffId={loadedQA?.staffMemberId} driver={driver} setDriver={setDriver} disabled={completed} />*/}
                            <div style={{ paddingTop: '5px', textAlign: 'left' }}>
                                <SubmitButtonDisplayer completed={completed} loading={loading} allowed={checkAccess(props.access, requiredEditAccess)} submitFunc={validate} handleClose={handleClose} />
                            </div>

                        </Grid>

                    </Grid>
                </Grid>


            </Div>
        </Box >
    );
};



const style = {
    position: 'absolute',
    top: '40%',
    left: '40%',
    transform: 'translate(-50%, -50%)',
    background: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    maxHeight: '90%',
    p: 4,
    overflow: 'auto',
    zIndex: 1000,
    padding: '20px'
};

const exitIcon = {
    position: 'absolute',
    right: '1%',
    top: '1%',
}

const PRNList = (props) => {
    //const [outputLines, setOutputLines] = React.useState((props.outputs && (props.outputs.length > 0)) ? props.outputs : [{ ...outputStruct }]);
    const [editUnitsStore, setEditUnitsStore] = React.useState(props.outputs.map((o) => null));
    const [openCB, setOpenCB] = React.useState(false);
    const [comboLabelUnits, setComboLabelUnits] = React.useState(props.quantPerUnit);
    const [comboLabelAllocations, setComboLabelAllocations] = React.useState([]);
    const Swal = useSwalWrapper();
    const updateLine = (index, field) => (val) => {
        var cpyPrns = [...props.outputs];
        if (field === 'units' && props.quantPerUnit) {
            cpyPrns[index].quantity = val * props.quantPerUnit;
        }
        cpyPrns[index][field] = val;
        props.setOutputs(cpyPrns);
    }

    const handleClose = () => {
        setOpenCB(false);
        setComboLabelAllocations([]);
    }


    const removeLine = (ind) => (e) => {
        var cpyPrns = [...props.outputs];
        cpyPrns.splice(ind, 1);
        props.setOutputs(cpyPrns);
    }
    const updateEditUnitsStore = (ind) => (val) => {
        var cpyPrns = [...editUnitsStore];
        cpyPrns[ind] = val;
        setEditUnitsStore(cpyPrns);
    }
    const openComboBuilder = () => {
        setOpenCB(true);
    }
    const addtoCombo = (pn) => () => {
        var cpyPrns = [...comboLabelAllocations];
        //check if already in list
        if (!cpyPrns.find((o) => (o.pn.ivn.stockItemId === pn.ivn.stockItemId) && (o.pn.ivn.lotSerialNbr === pn.ivn.lotSerialNbr) && (o.pn.ivn.expiryDate === pn.ivn.expiryDate))) {
            cpyPrns.push({ pn: pn, quantity: 1 });
        }
        var newTotals = Math.floor(comboLabelUnits / (cpyPrns.length));
        var remTotal = comboLabelUnits - (newTotals * (cpyPrns.length - 1));
        setComboLabelAllocations(cpyPrns.map((cp, ind) => {
            if (ind === cpyPrns.length - 1) {
                cp.quantity = remTotal;
            } else {
                cp.quantity = newTotals;
            }
            return cp;
        }));
    }
    const printLabel = () => {
        const pLabel = () => {
            saveLabel([comboLabelAllocations[0].pn], props.saleItem, props.codeInfo, props.codeInfo.isHalfshell, comboLabelUnits, comboLabelAllocations);
            setComboLabelAllocations([]);
            setOpenCB(false);
        };
        if (comboLabelAllocations.length > 0) {
            var warningMessages = [];
            var errorMessages = [];

            const formatErrorMessage = (array) => {
                var errString = '';
                array.map((a) => {
                    errString = errString + a + '<br/>';
                });
                return errString;
            }
            if (comboLabelAllocations.reduce((a, b) => a + Number(b.quantity), 0) !== Number(comboLabelUnits)) {
                warningMessages.push('Total of each PRN should equal total on label. Are you sure you want to contine?');
            }


            if (warningMessages.length > 0) {
                Swal.fire({
                    html: formatErrorMessage(warningMessages),
                    icon: 'warning',
                    title: 'Oops...',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, Continue',
                    cancelButtonText: 'No, I\'ll edit it',

                }).then(result => {
                    if (result.value) {
                        pLabel();
                    } else if (result.dismiss === Swal.DismissReason.cancel) {

                    }
                });
            } else {
                pLabel();
            }


        }
    }
    const addComboQuantity = (ind) => (val) => {
        var cpyPrns = [...comboLabelAllocations];
        cpyPrns[ind].quantity = val;
        setComboLabelAllocations(cpyPrns);
    }
    console.log(editUnitsStore)
    var totalQuant = 0;
    return <Div>{
        props.outputs ? props.outputs.map((pn, ind) => {
            //var invOfThisOut = props.relevantInv?.find((ri) => ((ri.lotSerialNbr === pn.ivn.lotSerialNbr) && (ri.expiryDate === pn.ivn.expiryDate) && (ri.stockItemId === pn.ivn.stockItemId)))?.portalQuantity;
            var existingOfThisOut = props.existingPRNs?.find((ri) => ((ri.ivn.lotSerialNbr === pn.ivn.lotSerialNbr) && (ri.ivn.expiryDate === pn.ivn.expiryDate) && (ri.ivn.stockItemId === pn.ivn.stockItemId)))?.quantity
            totalQuant += Number(pn?.quantity);
            return (<Grid container spacing={2} mb={1} key={pn.id} >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={6} onClick={openCB ? addtoCombo(pn) : () => { }}>
                    <Typography variant={"h5"} mb={0}  >{getPRNfrmLSN(pn.ivn.lotSerialNbr) + '    (Use by: ' + dateFormat(pn.ivn.expiryDate) + ')'}</Typography>
                    <Typography variant={"h5"} mb={0}>{pn.ivn.stockItem.description + ' (' + (pn.ivn.portalQuantity ? pn.ivn.portalQuantity :
                        (Number(pn.newIvn?.portalQuantity ? pn.newIvn?.portalQuantity : 0) + Number(existingOfThisOut))) + ')'}</Typography>
                </Grid>
                {/*<Grid item xs={12} sm={6} md={6} lg={3} xl={3}>*/}
                {/*    <ControlledTextField key={'unit' + ind} title="Units" state={pn.units} setState={updateLine(ind, 'units')} disabled={props.disabled} number />*/}
                {/*</Grid>*/}
                <Grid item xs={11} sm={5} md={5} lg={4} xl={4}>
                    <ControlledTextField key={'qua' + ind} title="Quantity" state={pn.quantity} setState={updateLine(ind, 'quantity')} disabled={props.disabled} number width='150px' />
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                    <IconButton aria-label="Label" onClick={() => { saveLabel([pn], { customer: { name: props.warehouse?.name } }, pn?.ivn?.stockItem, pn?.ivn?.stockItem?.isHalfshell, editUnitsStore[ind]) }} >
                        <ArticleIcon />
                    </IconButton>
                    {editUnitsStore[ind] ? <ControlledTextField key={'equa' + ind} title="Label Oys/Unit" state={editUnitsStore[ind]} setState={updateEditUnitsStore(ind)} disabled={props.disabled} number width='100px' /> :
                        <IconButton aria-label="Label" onClick={() => { updateEditUnitsStore(ind)(pn?.ivn?.stockItem?.bagCount) }} >
                            <BorderColorIcon />
                        </IconButton>}
                    <IconButton aria-label="Label" onClick={removeLine(ind)} >
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>)
        }) : null
    }

        <Grid item xs={12} >
            <Typography variant={"h3"} mb={0}>Total Allocated: {totalQuant ? numberFormat(totalQuant) : 0}</Typography>
            {props.isHalfshell ? <Button variant="contained" color="primary" onClick={openComboBuilder} disabled={props.disabled}>Combo Label</Button> : null}
        </Grid>
        {openCB ? <Div style={style} >
            <IconButton style={exitIcon} aria-label="close" onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h5" component="h2">
                Choose Allocations to Combine
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {comboLabelAllocations.map((o, ind) => <Grid container spacing={2} mb={1} >
                    <Typography style={{ padding: '10px', marginTop: '10px' }} >{o.pn.ivn.lotSerialNbr}</Typography>
                    <ControlledTextField key={ind} title="doz" state={o.quantity} setState={addComboQuantity(ind)} disabled={props.disabled} number width='70px' />
                </Grid>)}
            </Typography>
            <ControlledTextField key={'dfgdf'} title="Label Oys/Unit" state={comboLabelUnits} setState={setComboLabelUnits} disabled={props.disabled} number width='100px' />
            <Button style={{ marginTop: '10px' }} variant="contained" color="primary" onClick={printLabel} disabled={props.disabled}>Print Label</Button>

        </Div> : null}
    </Div>;
}

export default FulfilmentForm;

