import React from 'react';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { startOfWeek, endOfWeek } from "../../utils/dateFunctions";
import DateSelector from "../../shared/DateSelector";
import WarehouseSelector from "../../components/WarehouseSelector";
import SalesOrdersCreate from './FunctionalComp/SalesOrdersCreate';
import FormModal from '../../components/FormModal';
import ProjectSales from './Forms/ProjectSales';
import SalesOrdersEditable from './Tables/SalesOrdersEditable';
import SalesSeries from './Tables/SalesSeries';
import AddSalesOrder from './Forms/AddSalesOrder';
import { checkAccess } from '../../utils/roleFunctions';
import { ACCESS, ACCESS_SECTIONS } from '../../utils/constants/access';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
const SalesOrdersPage = (props) => {
    const [startDate, setStartDate] = React.useState(startOfWeek(new Date()));
    const [endDate, setEndDate] = React.useState(endOfWeek(new Date()));
    const [data, setData] = React.useState(null);
    const [completed, setCompleted] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const [series, setSeries] = React.useState(true);
    const [location, setLocation] = React.useState(0);
    const [reload, setReload] = React.useState(true);
    const [status, setStatus] = React.useState([0]);
    const requiredEditAccess = { key: ACCESS_SECTIONS.SALES, value: ACCESS.EDIT };

    const reloadFunc = () => {
        setReload(!reload);
    }

    const handleClose = () => {
        setCompleted(false);
        setEdit(false);
        setData(null);
        setOpen(false);
        reloadFunc();
    }
    const viewPO = (array) => (rowd, rowState) => {
        setData(array[rowState.dataIndex]);
        setOpen(true);
        setEdit(true);
        setCompleted(false);
    }
    const filter = (array) => {
        return array.filter((da) => {
            var today = new Date();

            if (status.includes(0) && (today < new Date(da.unFormEndDate))) {
                return true;
            }
            if (status.includes(1) && (today > new Date(da.unFormEndDate))) {
                return true;
            }
            return false;
        })
    }
    return (
        <React.Fragment>
            <FormModal open={open} handleClose={handleClose} title={'Edit Sales Order'} >
                <AddSalesOrder data={data} access={props.access} reload={reloadFunc} handleClose={handleClose} edit={edit} series={series} completed={completed} />
            </FormModal>
            {/* <Grid container spacing={2} mb={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <ToggleButtonGroup value={status}  onChange={(e, v) => { setStatus(v) }} color="primary">
                        <ToggleButton value={0}>Active</ToggleButton>
                        <ToggleButton value={1}>Closed</ToggleButton>
                    </ToggleButtonGroup>

                </Grid>
            </Grid> */}
            
            <Grid container spacing={3.5}>
                
                <Grid item xs={12}>
                    {checkAccess(props.access, requiredEditAccess) ?
                        <SalesOrdersCreate access={props.access} reloadFunc={reloadFunc} reload={reload} createSeries/> :
                        null}
                </Grid>
                <Grid item xs={12}>
                    <SalesSeries location={location} startDate={startDate} endDate={endDate}  access={props.access} onClick={viewPO}  reloadFunc={reloadFunc} reload={reload} dashboard/>
                </Grid>
        </Grid>
        </React.Fragment >
    );
};

//const RollOutButton = (props) => {
//    const [open, setOpen] = React.useState(false);
//    const [completed, setCompleted] = React.useState(false);

//    const openForm = () => {
//        setOpen(true);

//    }
//    const handleClose = () => {
//        setCompleted(false);
//        setOpen(false);
//    }


//    return <React.Fragment>
//        <FormModal open={open} handleClose={handleClose} title={"Project Sales"} >
//            <ProjectSales access={props.access} reload={props.reloadFunc} handleClose={handleClose} completed={completed} />
//        </FormModal>
//        <Button onClick={openForm} variant="contained" style={{ marginBottom: '5px' }}>{"Project Sales forward"}</Button>

//    </React.Fragment>
//}

export default SalesOrdersPage;
